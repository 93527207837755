.input {
  display: flex;
  align-items: center;
  justify-content: center;
}
.parent{
  display: flex;
  width: 100%;
}

.child{
  flex: 1;
  margin: 10px;
  padding-top: 3em;
}

.varstack-logo{
  height: 15rem;
}

.data{
  align-self:stretch;
}

.welcome{
  font-weight: bold;
  font-size: larger;
}

.inputs {
  
  padding: 3rem;
  padding-top: 0rem;
  padding-bottom: 0.5rem;
  width: 60rem;
  margin-bottom: 10px;
  background-color: white;
  margin: 1rem 0 0 0;
  margin-top: 0em;
  /* margin: auto; */
  /* margin-left: 35%; */
  @media only screen and (max-width: 600px) {
    
      width:25em;
    
  }
}

.input-boxes {
  display: flex;
  /* flex-direction: row; */
  flex-wrap: wrap;
  justify-content: space-around;
  text-align: left;
}
.input-box-peptide {
  width: 32.5em;
  height: 2.5em;
  border: 1px solid #dee2e6;
  border-radius: 6px;
  @media only screen and (max-width: 600px) {
      width:17.5em;
  }
}
.peptide_change_selector {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 0.9rem;
  margin-top: 1rem;
  @media only screen and (max-width: 600px) {
      width:18em;
  }
  
}

.cancer_select{
  margin-bottom: 0.5rem;
  font-size: medium;
}
.input-box-cancer {
  width: 32.5em;
  height: 2.5em;
  /* font-size: 0.8rem; */
  border: 1px solid #dee2e6;
  border-radius: 6px;
  @media only screen and (max-width: 600px) {
    
      width:17.5em;
  }
}


.smart-search{
  position: relative;
  top: 10px;
  padding: 3rem;
  padding-top: 0rem;
  padding-bottom: 1rem;
  width: 60rem;
  background-color: white;
  margin: 0rem 0 0 0;
  margin: auto;
}
.input-box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.input-labels {
  flex: 1;
}
.btn_container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.submit-button {
  width: 32.5em;
  height: 2.5em;
  border: none;
  /* padding-bottom: 0.5rem; */
  border-radius: 6px;
  background: rgb(141, 138, 203);
  background: linear-gradient(
    0deg,
    rgba(141, 138, 203, 1) 9%,
    rgba(67, 96, 159, 1) 46%,
    rgba(67, 96, 159, 1) 51%
  );
  color: white;
  font-size: large;
}

.data-container {
  margin: 3rem 0;
  outline: 2px solid rgb(241, 241, 248);
  height: 40em;
  overflow: auto;
  border: 4px solid #d0d9eb;
  border-radius: 4px;
  padding: 0.8rem;
  margin-left: 4em;
  margin-right: 4em;
  background-color: #f8f9fb;

  /* Styling the scrollbar */
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #000000 #f1f1f8; /* For Chrome, Edge, and Safari */
}

.data-container::-webkit-scrollbar {
  width: 8px; /* Width of the vertical scrollbar */
}

.data-container::-webkit-scrollbar-track {
  background-color: transparent; /* Color of the track */
}

.data-container::-webkit-scrollbar-thumb {
  background-color: #adafb1; /* Color of the thumb */
  border-radius: 8px;
}
.data-container::-webkit-scrollbar-thumb:horizontal {
  background-color: #adafb1; /* Color of the horizontal thumb */
  border-radius: 8px;
}

.data-table {
  overflow: auto;
}
.data-table::-webkit-scrollbar {
  width: 8px; /* Width of the vertical scrollbar */
}

.data-table::-webkit-scrollbar-track {
  background-color: transparent; /* Color of the track */
}

.data-table::-webkit-scrollbar-thumb {
  background-color: #adafb1; /* Color of the thumb */
  border-radius: 8px;
}
.data-table::-webkit-scrollbar-thumb:horizontal {
  background-color: #adafb1; /* Color of the horizontal thumb */
  border-radius: 8px;
}


iframe {
  width: 100em;
  height: 100em;
}

.dataset-name {
  font-size: 2em;
  font-weight: bold;
  font-family: sans-serif;
}

table {
  /* width: 800px; */
  /* height: 200px; */
  overflow: scroll;
  border-collapse: collapse ;
  border: 2px solid rgb(221 221 234) ;
  border-radius: 8px;
}

th {
  /* border: 1px solid black; */
  text-align: center;
  font-weight: 600;
  min-width: 8rem;
}

td {
  /* border: 1px solid black; */
  text-align: center;
  font-weight:400;
  font-size: small;
}
tr:nth-child(even) {
  background-color: #f6f6f6; /* Color for even rows */
}

tr:nth-child(odd) {
  background-color: white; /* Color for odd rows */
}
.chart-wrap {
  width: 650px;
  height: 600px;
  font-family: sans-serif;
  padding: 1em;
  .title {
    text-align: center;
  }
}

.grid {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-bottom: 2px solid #aaa;
  background: repeating-linear-gradient(
    0deg,
    transparent,
    transparent 19.5%,
    fadeout(#aaa, 30%) 20%
  );

  .bar {
    background-color: #3868bb;
    width: 45px;
    height: var(--bar-value);
    align-self: flex-end;
    margin: 1 auto;
    border-radius: 3px 3px 0 0;
    position: relative;

    &.bar:hover {
      opacity: 0.7;
    }

    &::after {
      content: attr(data-name);
      top: -3em;
      padding: 10px;
      display: inline-block;
      white-space: nowrap;
      position: absolute;
      transform: rotate(-45deg);
    }
  }

  &.horizontal {
    flex-direction: column;
    border-bottom: none;
    border-left: 2px solid #aaa;
    background: repeating-linear-gradient(
      90deg,
      transparent,
      transparent 19.5%,
      fadeout(#aaa, 30%) 20%
    );
    .bar {
      height: 45px;
      width: var(--bar-value);
      align-self: flex-start;
      margin: auto 0 auto 0;
      border-radius: 0 3px 3px 0;

      &::after {
        top: initial;
        left: 100%;
        padding: 0 10px;
        display: inline-block;
        white-space: nowrap;
        position: absolute;
        transform: rotate(0deg);
        line-height: 45px;
      }
    }
  }
}
.input_fields_main {
  margin: 2rem 0;
  font-size: 0.85rem;
  font-weight: normal;
}
.form-control{
  font-weight: normal;
}
.input_fields_main .form-control::placeholder {
  font-size: 0.9rem; 
  color: #999; 
  font-weight: normal;
}
.window {
  background-color: #c9d7f5;
  padding: 1rem;
  overflow: auto;
  height: 100vh;
}
.window::-webkit-scrollbar {
  width: 10px; /* Width of the vertical scrollbar */
}

.window::-webkit-scrollbar-track {
  background-color: transparent; /* Color of the track */
}

.window::-webkit-scrollbar-thumb {
  background-color: #bcc1c5; /* Color of the thumb */
  border-radius: 8px;
}


