.about_body{
  /* display: flex; */
  /* flex-direction: column; */
  /* align-items:center; */
  /* justify-content: flex-end; */
  font-family: 'Roboto', sans-serif;;
  overflow: auto;
  padding: 1rem;
  padding-bottom: 10rem;
  overflow: auto;
  height: 100vh;  
    
}
p{
  font-weight: 500;
}
.about_section{
  display: flex;
  align-items: center;
  text-align: center;
  word-wrap: break-word ;
  text-shadow: #bcc1c5;
}
.about::-webkit-scrollbar {
    width: 10px; /* Width of the vertical scrollbar */
  }
  
  .about::-webkit-scrollbar-track {
    background-color: transparent; /* Color of the track */
  }
  
  .about::-webkit-scrollbar-thumb {
    background-color: #bcc1c5; /* Color of the thumb */
    border-radius: 8px;
  }

  .images{
    width: 50em
  }
  ul{
    list-style-type:none;
    
  }
  li{
    display: flex;
    justify-content: center;
  }
  
  h4{
    margin-top: 25px;
    font-weight: bold;
  }