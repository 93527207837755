.header_container{
    position: sticky;
    top: 0.01rem;
    background-color: #43609f;
    height: 60px;
    z-index: 9999;
    .main_row{
        position: fixed;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        img{
            height: 60px;
            width: 200px;
            padding: 0;
        }
    }
    
}